<template>

  <el-form
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="row g-9 mb-7">
          <h4>Google</h4>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Client Id</label>
            <el-form-item prop="google.client_id" :rules="rules['field']">
              <el-input v-model="objData.google.client_id" placeholder="Client Id"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Client Secret</label>
            <el-form-item prop="google.client_secret" :rules="rules['field']">
              <el-input v-model="objData.google.client_secret" placeholder="Client Secret"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Redirect</label>
            <el-form-item prop="google.redirect" :rules="rules['field']">
              <el-input v-model="objData.google.redirect" placeholder="Redirect"></el-input>
            </el-form-item>
          </div>

        </div>
        <div class="row g-9 mb-7">
          <h4>Facebook</h4>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Client Id</label>
            <el-form-item prop="facebook.client_id" :rules="rules['field']">
              <el-input v-model="objData.facebook.client_id" placeholder="Client Id"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Client Secret</label>
            <el-form-item prop="facebook.client_secret" :rules="rules['field']">
              <el-input v-model="objData.facebook.client_secret" placeholder="Client Secret"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Redirect</label>
            <el-form-item prop="facebook.redirect" :rules="rules['field']">
              <el-input v-model="objData.facebook.redirect" placeholder="Redirect"></el-input>
            </el-form-item>
          </div>

        </div>
        <div class="row g-9 mb-7">
          <h4>Apple</h4>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Client Id</label>
            <el-form-item prop="apple.client_id" :rules="rules['field']">
              <el-input v-model="objData.apple.client_id" placeholder="Client Id"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Client Secret</label>
            <el-form-item prop="apple.client_secret" :rules="rules['field']">
              <el-input v-model="objData.apple.client_secret" placeholder="Client Secret"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Redirect</label>
            <el-form-item prop="apple.redirect" :rules="rules['field']">
              <el-input v-model="objData.apple.redirect" placeholder="Redirect"></el-input>
            </el-form-item>
          </div>

        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, ref, toRefs,watch,onMounted} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import router from "@/router";
import {APIs} from "@/store/enums/ApiEnums";
import {getCities} from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import { useRouter } from 'vue-router'
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "payment-setting-comp",
  components: {ImageComp},
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const {type} = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref([]);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Social Setting", ['Settings']);
    });
    objData.value = {
      google : {
        client_id : '',
        client_secret : '',
        redirect : '',
      },
      facebook : {
        client_id : '',
        client_secret : '',
        redirect : '',
      },
      apple : {
        client_id : '',
        client_secret : '',
        redirect : '',
      }
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      try {
        response = await ApiAxiosService.get(APIs.SETTING.social_setting);
        objData.value.google = response.data.data.google ? response.data.data.google : objData.value.google;
        objData.value.facebook = response.data.data.facebook ? response.data.data.facebook : objData.value.facebook ;
        objData.value.apple = response.data.data.apple ? response.data.data.apple : objData.value.apple;
      } catch (e) {
        handleError(e)
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          updateResource();

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const updateResource = () => {
      loading.value = true;
      ApiAxiosService.post(APIs.SETTING.social_setting , objData.value).then(function (res) {
        loading.value = null;
        handleSuccess(res);
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }


    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      updateResource,
      type,
      isIndeterminate,
      languages
    };
  },

});
</script>

